import React from "react"
import styled from "styled-components"

import ContentWrapper from "../styles/ContentWrapper"

const StyledFooter = styled.footer`
font-size: 0.8rem;
line-height:1rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 0.2fr;
  gap: 2rem 2rem;
  grid-template-areas:
    ".";
  grid-area: Footer;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
   display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 2rem;
  grid-template-areas:
    ". . . .";
   }
    .footer-links {
      /* Adjust width of links wrapper accordingly */
      width: 10rem;
      display: flex;
      justify-content: space-between;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 15rem;
      }
    }
  }
`

const Footer = () => (
  <StyledFooter>
    <StyledContentWrapper>
      <div>All contents of this website are the property of Everything Original™. No part of this site, including all text and images, may be reproduced in any form without the prior written consent of Everything Original™.</div> 
      <div>Will send book and work samples on request.<br/> All views, writing and works are his own. They do not reflect the company that employs him.</div>

      <div>© 2020 by Everything Original™<br/>
      Independently owned and operated.</div>
    </StyledContentWrapper>
  </StyledFooter>
)

export default Footer
