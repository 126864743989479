import { createGlobalStyle } from 'styled-components'
import modernNormalize from "styled-modern-normalize"

import NeueEOWoff2 from '../fonts/NeueEODisplay65Medium.woff2';
import NeueEOWoff from '../fonts/NeueEODisplay65Medium.woff';

import IcoEOEot from '../fonts/icomoon.eot';
import IcoEOWoff from '../fonts/icomoon.woff';


const GlobalStyle = createGlobalStyle`
    
    // Import normalize.css
    ${modernNormalize}

    * {
        box-sizing: border-box;
    }
    html {
        width: 100%;
        height: 100%;
    }

    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FE4B11;
        color: ${({ theme }) => theme.colors.text};
        font-size: 1rem;
        font-family: 'Neue EO Display 65';
        line-height: 1.5rem;
        text-rendering: optimizeLegibility;
        &.blur {
            overflow: hidden;
            #___gatsby #main-content > * {
              filter: blur(5px) ;
              transition: all .3s ease-out;
              pointer-events: none;
              user-select: none;
            }
          }
        }
        &.splashScreen {
              position: fixed;
              overflow: hidden;
        }
    @font-face {
    font-family: 'Neue EO Display 65';
    src: url(${NeueEOWoff2}) format('woff2'),
         url(${NeueEOWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    }

    @font-face {
    font-family: 'icomoon';
    src:  url(${IcoEOEot});
    src:  url(${IcoEOWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
    }

    [class^="icon-"], [class*=" icon-"] {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1rem;
      font-size:1.7em;
      margin-right: 1.5rem;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: all 0.1s ease-out;
      color: ${({ theme }) => theme.colors.primary};
      &:hover {
        background-position: left bottom;
        color: #ffffff;
      }
      &:hover svg {
        /* Change svg color to white */
        filter: brightness(0) invert(1);
      }
    }

    .icon-email:before {
      content: "\\e900";
    }
    .icon-medium:before {
      content: "\\e901";
    }
    .icon-swoosh:before {
      content: "\\e902";
    }
    .icon-linkedin2:before {
      content: "\\eaca";
    }
    .icon-instagram:before {
      content: "\\ea92";
    }
    .icon-pinterest2:before {
      content: "\\ead2";
    }
    .icon-twitter:before {
      content: "\\ea96";
    }
    a {
        display: inline-block;
        text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        cursor: pointer;
        &:hover,
        &:focus {
          outline: 0;
        }
    }

    h1 {
        font-weight: 500;
        font-size: 4rem;
        line-height: 3.6rem;
        letter-spacing: -0.1rem;
        color: ${({ theme }) => theme.colors.primary};
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 6rem;
            line-height: 5.6rem;
            letter-spacing: -0.2rem;
        }
    }

    h2 {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.0rem;
        color: ${({ theme }) => theme.colors.primary};
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            font-size: 2.5rem;
            line-height: 2.4rem;
        }
    }

    h3 {
        font-weight: 00;
        font-size: 1.75rem;
        line-height: 2.25rem;
        color: ${({ theme }) => theme.colors.primary};
        margin-bottom: 3rem;
    }

    h4 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
    }

    p{
     line-height: 1.3rem;
     @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        line-height: 2.2rem;
        }
    }

    hr {
        margin: 3rem auto;
        border-width: .05rem;
        color: ${({ theme }) => theme.colors.tertiary};
        opacity: 0.1;
      }

`;

export default GlobalStyle